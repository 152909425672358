import { createAnalyticsGroup } from 'js/lib/analytics'

const logo = document.querySelectorAll('.js-c-logo--partner')

if (logo.length > 0) {

  const currentPage = window.location.href
  
  logo.forEach(link => {   
    
    const partner = link.getAttribute('data-tracking')
    const track = createAnalyticsGroup(`partner-logo--${partner}`)
    
    if (link) {
      link.addEventListener('click', () => {
        track('click', `Pet Circle logo from ${currentPage}`)
      })
    }
  })
}
