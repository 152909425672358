import { createAnalyticsGroup } from 'js/lib/analytics'

const banner = document.querySelectorAll('.js-c-promo__leaderboard--edi')

if (banner.length > 0) {
  const track = createAnalyticsGroup('edi--leaderboard')
  const currentPage = window.location.href

  banner.forEach(card => {
    const links = card.querySelectorAll('a')
    links.forEach(link => {
      link.addEventListener('click', () => {
        track('click', `EDI banner from ${currentPage}`)
      })
    })
  })
}
