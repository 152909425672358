import { createAnalyticsGroup } from 'js/lib/analytics'

const disclaimer = document.querySelector('.js-c-footer__edi-disclaimer')

if (disclaimer) {
  const track = createAnalyticsGroup('edi--footer-disclaimer')
  const currentPage = window.location.href
  const links = disclaimer.querySelectorAll('a')

  links.forEach(link => {
    const linkText = link.textContent.trim()

    link.addEventListener('click', () => {
      track('click', `${linkText} from ${currentPage}`)
    })
  })
}
