import { on } from 'js/lib/events'
import { createAnalyticsGroup } from 'js/lib/analytics'

const el = '.js-c-promo-banner-edi--tracking'

on('click', el, event => {
  const anchorElement = event.target.closest(`a${el}`)

  if (anchorElement) {
    const track = createAnalyticsGroup(anchorElement.getAttribute('data-category'))
    const clickValue = anchorElement.getAttribute('data-click')
    track('click', clickValue)
  }
})
