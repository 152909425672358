import { createAnalyticsGroup } from 'js/lib/analytics'

const ediLogo = document.querySelectorAll('.js-c-logo--pet_circle')

if (ediLogo.length > 0) {
  const track = createAnalyticsGroup('partner-logo--pet_circle')
  const currentPage = window.location.href
  
  ediLogo.forEach(link => {
    
    if (link) {
      link.addEventListener('click', () => {
        track('click', `Pet Circle logo from ${currentPage}`)
      })
    }
  })
}
